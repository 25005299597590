import ChatResponse from './chatResponse';

const ChatResult = () => {
  return (
    <div className="chat-result">
      <div class="chatbox">
        <div class="chat-content">
          <div className="messages">
            <div class="botanswer">
              <p><span class="block font-bold text-gray-700">AI - </span>Hi, I am an AI Agent. How can I help you?</p>
            </div>
              <ChatResponse />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatResult;