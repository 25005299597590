import { useDataContext } from '../response-context';

const ChatResponse = () => {
    const { loading } = useDataContext()

    const chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
    const chatResponse = JSON.parse(localStorage.getItem('chatResponse')) || [];

    const uniqueResponses = new Set();

    const combinedChat = chatHistory.concat(chatResponse);

    
    const filteredChatArray = combinedChat.filter((chatItem) => {
        if (chatItem.type !== 'response') {
            return true;
        }
        
        const responseKey = `${chatItem.text2}_${chatItem.text ? chatItem.text.length : 0}`;
        
        if (!uniqueResponses.has(responseKey)) {
            uniqueResponses.add(responseKey);
            return true;
        }
        
        return false;
    });
    
    const inputMessages = filteredChatArray.filter((chatItem) => chatItem.type === 'input');
    const responseMessages = filteredChatArray.filter((chatItem) => chatItem.type === 'response');
    
    const chatItems = [];
    let i = 0;
    let j = 0;
    
    
    while (i < inputMessages.length || j < responseMessages.length) {
        if (i < inputMessages.length) {
            chatItems.push(inputMessages[i]);
            i++;
        }
        if (j < responseMessages.length) {
            chatItems.push(responseMessages[j]);
            j++;
        }
    }

    return (
        <>
        {
            loading ? (
                <div className='botanswer'>Please wait while we load your data</div>
            ) : (
                    chatItems.map((chatItem, index) => (
                        <div id={index} key={index}>
                            {chatItem.type === 'input' && (
                                <div className='chatInput'>
                                {chatItem.text}
                                </div>
                            )}
                            {
                                chatItem.type === 'response' && (
                                    <div>
                                        <div className='botanswer' key={index}>
                                            <p>{chatItem.text2}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ))
                )
        }   
        </>
    )
}

export default ChatResponse;