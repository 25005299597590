import { Container  } from 'react-bootstrap';
import ChatResult from './chatresult';
import ChatInput from './chatinput';
import { DataProvider } from '../response-context';
function Main() {
  return (
    <>
      <DataProvider>
        <div className="wrapper">
          <Container>
            <div className="chat-container">
              <ChatResult />
              <ChatInput />
            </div>
          </Container>
        </div>
      </DataProvider>
    </>
  );
}

export default Main;
