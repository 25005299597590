import { useEffect, useState } from 'react';
import {InputGroup, Form, Button} from 'react-bootstrap';
import { Icon } from 'react-icons-kit';
import {ic_subdirectory_arrow_right_twotone} from 'react-icons-kit/md'
import { useDataContext } from '../response-context';


const ChatInput = () => {
  const [input, setInput] = useState('')
  const { updateResponseData, responseData, startLoading, stopLoading } = useDataContext();
  const [inputChat, setInputChat] = useState(false);
  
  useEffect(() => {
    const chatInputHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];
    if(chatInputHistory.length){
      setInputChat(true)
    }
  }, [])

  const storeChatHistory = (responseText) => {
    const chatResponse = JSON.parse(localStorage.getItem('chatResponse')) || [];

    chatResponse.push({ type: 'response', text: responseText.data, text2: responseText.response });

    localStorage.setItem('chatResponse', JSON.stringify(chatResponse));
  };

  const onSubmit = async() => {
    const chatHistory = JSON.parse(localStorage.getItem('chatHistory')) || [];

    chatHistory.push({ type: 'input', text: input});
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
    const postData = {
      prompt: input,
    };
    try {
      startLoading()
      const response = await fetch('https://langchain-api.dealopia.com/get_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      
      if (response.ok) {
        const data = await response.json();
        storeChatHistory(data);
        updateResponseData(data)
        setInput('');
        stopLoading()
      } else {
        console.error('API request failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  const handleClearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (responseData) {
      storeChatHistory(responseData);
    }
  }, [responseData]);
  
  return (
    <>
    {
      responseData || inputChat ? (
        <>
          <Button variant="danger" id="button-addon2" onClick={handleClearLocalStorage}>Clear chat and reload</Button>
          <div className="chat-input">
            <InputGroup>
              <Form.Control
                placeholder="Type your data query here"
                aria-label="Type your data query here"
                aria-describedby="basic-addon2"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onSubmit();
                  }
                }}
              />
              <Button variant="flat" id="button-addon2" onClick={onSubmit}>
                <Icon size={24} icon={ic_subdirectory_arrow_right_twotone} />
              </Button>
            </InputGroup>
          </div>
        </>
      ) : (
        <div className="chat-input">
          <InputGroup>
            <Form.Control
              placeholder="Type your data query here"
              aria-label="Type your data query here"
              aria-describedby="basic-addon2"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
            <Button variant="flat" id="button-addon2" onClick={onSubmit}>
              <Icon size={24} icon={ic_subdirectory_arrow_right_twotone} />
            </Button>
          </InputGroup>
        </div>
      )
    }
        
    </>
  );
}

export default ChatInput;
