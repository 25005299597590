// DataContext.js

import { createContext, useContext, useState, useCallback } from 'react';

const DataContext = createContext();

export const useDataContext = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const updateResponseData = useCallback((data) => {
    setResponseData(data);
  }, []);

  return (
    <DataContext.Provider value={{ loading, responseData, startLoading, stopLoading, updateResponseData }}>
      {children}
    </DataContext.Provider>
  );
};